import { useEffect, useMemo, useRef, useState } from 'react';
import { useScroll } from '@use-gesture/react';

import { getScrollTop, getWindowHeight } from '../utils/window';

export function useHeaderState({ showAlwaysZoneHeight }) {
  const [compressed, setCompressed] = useState(false);
  const [visible, setVisible] = useState(true);
  const visibleRef = useRef(visible);
  const compressedRef = useRef(compressed);

  useEffect(
    function effect() {
      if (getScrollTop() >= showAlwaysZoneHeight) {
        setCompressed(true);
      }
    },
    [],
  );

  useScroll(
    function handler({ xy, memo = getScrollTop() }) {
      const [, y] = xy;

      if (y === memo) {
        return y;
      }

      if (y < showAlwaysZoneHeight) {
        if (!visibleRef.current) {
          visibleRef.current = true;
          setVisible(true);
        }

        if (compressedRef.current) {
          compressedRef.current = false;
          setCompressed(false);
        }

        return y;
      }

      if (!compressedRef.current) {
        compressedRef.current = true;
        setCompressed(true);
      }

      const diff = memo - y;

      if (diff <= 0) {
        if (visibleRef.current) {
          visibleRef.current = false;
          setVisible(false);
        }

        return y;
      }

      if (diff >= getWindowHeight() / 10) {
        if (!visibleRef.current) {
          visibleRef.current = true;
          setVisible(true);
        }

        return y;
      }

      return memo;
    },
    { target: typeof window === 'undefined' ? undefined : window }
  );

  return useMemo(
    function factory() {
      return { compressed, visible };
    },
    [compressed, visible],
  );
}
