import { useEffect, useState } from 'react';

import { useScroll } from './useScroll';

import { DIRECTION } from '../constants';

export function useAnimationIndex({ thresholds }) {
  const { direction, position } = useScroll();

  function getIndex() {
    switch (direction) {
      case DIRECTION.DOWN: {
        for (let i = thresholds.length - 1; i > 0; i -= 1) {
          if (position > thresholds[i - 1][0]) return i;
        }

        return 0;
      }

      case DIRECTION.UP: {
        for (let i = 0; i < thresholds.length; i += 1) {
          if (position < thresholds[i][1]) return i;
        }

        return thresholds.length - 1;
      }

      default: {
        return 0;
      }
    }
  }

  const cancel = position < thresholds[0][0] || position >= thresholds[thresholds.length - 1][1];
  const [index, setIndex] = useState(getIndex);

  useEffect(
    function effect() {
      if (cancel) return;

      const newIndex = getIndex();

      if (newIndex !== index) {
        setIndex(newIndex);
      }
    },
    [position],
  );

  return index;
}
