import React, { createContext, useEffect, useState } from 'react';

import { useMount } from '../hooks';

export const LoadingContext = createContext(false);
LoadingContext.displayName = 'LoadingContext';

export default function ContextWrapper({ children }) {
  const mounted = useMount();
  const [loaded, setLoaded] = useState(false);

  useEffect(
    function effect() {
      if (typeof window === 'undefined') return;

      document.body.classList.remove('no-overflow');

      setTimeout(
        function callback() {
          if (!mounted.current) return;

          setLoaded(true);

          const loader = document.querySelector('#loader');

          if (loader) {
            loader.remove();
          }
        },
        500,
      );
    },
    [],
  );

  return (
    <LoadingContext.Provider value={loaded}>
      {children}
    </LoadingContext.Provider>
  );
}
