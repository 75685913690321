import { useEffect, useMemo, useState } from 'react';

import { useScroll } from './useScroll';

import { DIRECTION } from '../constants';

export function useDivision({ enabled = true, items, thresholds, ...rest }) {
  const context = useScroll();
  const { direction, position } = context.direction ? context : rest;

  function getIndex(oldIndex) {
    if (position < thresholds[0][0]) {
      return 0;
    }

    if (position > thresholds[items.length - 1][0]) {
      return items.length - 1;
    }

    function positionedOnItem(index) {
      switch (direction) {
        case DIRECTION.DOWN: {
          return (
            position <= thresholds[index][1] - 1 && (
              index === 0 ||
              position > thresholds[index - 1][1] - 1
            )
          );
        }

        case DIRECTION.UP: {
          return (
            position >= thresholds[index][0] && (
              index >= items.length - 1 ||
              position < thresholds[index + 1][0]
            )
          );
        }

        default: {
          return false;
        }
      }
    }

    if (direction && Number.isFinite(oldIndex)) {
      if (direction === DIRECTION.UP) {
        for (let i = oldIndex; i >= 0; i -= 1) {
          if (positionedOnItem(i)) return i;
        }
      } else if (direction === DIRECTION.DOWN) {
        for (let i = oldIndex; i < items.length; i += 1) {
          if (positionedOnItem(i)) return i;
        }
      }
    } else {
      for (let i = 0; i < items.length; i += 1) {
        if (positionedOnItem(i)) return i;
      }
    }

    return 0;
  }

  const [index, setIndex] = useState(getIndex);

  useEffect(
    function effect() {
      if (!enabled || position === -1) return;

      const newIndex = getIndex(index);

      if (newIndex !== index) {
        setIndex(newIndex);
      }
    },
    [position],
  );

  return useMemo(
    function factory() {
      return { index, item: items[index] };
    },
    [index],
  );
}
