import { useRef, useState } from 'react';
import { useScroll } from '@use-gesture/react';

import { getScrollTop, getWindowHeight } from '../utils/window';

import { DIRECTION } from '../constants';

function getInitialState() {
  return typeof document === 'undefined'
    ? { direction: DIRECTION.DOWN, position: 0 }
    : { direction: DIRECTION.DOWN, position: getScrollTop() / getWindowHeight() };
}

export function useScrollPosition() {
  const [state, setState] = useState(getInitialState);
  const stateRef = useRef(state);

  useScroll(
    function handler({ xy, delta }) {
      const [, y] = xy;
      const [, dy] = delta;
      const position = y / getWindowHeight();
      const direction = dy === 0
        ? stateRef.current.direction
        : dy > 0
          ? DIRECTION.DOWN
          : DIRECTION.UP;

      if (position !== stateRef.current.position || direction !== stateRef.current.direction) {
        stateRef.current.direction = direction;
        stateRef.current.position = position;
        setState({ direction, position });
      }
    },
    { target: typeof window === 'undefined' ? undefined : window },
  );

  return state;
}
