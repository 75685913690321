import React from 'react';

import Bounds from './contexts/bounds';
import Loading from './contexts/loading';

import './styles/index.css';
import './styles/loader.css';

export function wrapPageElement({ element, props }) {
  return (
    <Bounds {...props}>
      <Loading>
        {element}
      </Loading>
    </Bounds>
  );
}
