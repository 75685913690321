import React, { createContext, useMemo } from 'react';

export const ScrollContext = createContext({ direction: undefined, position: -1 });
ScrollContext.displayName = 'ScrollContext';

export default function ContextWrapper({ children, direction, position }) {
  const value = useMemo(
    function factory() {
      return {
        direction,
        position,
      };
    },
    [direction, position],
  );

  return (
    <ScrollContext.Provider value={value}>
      {children}
    </ScrollContext.Provider>
  );
}
