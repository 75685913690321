import { useMemo } from 'react';

import { useScroll } from './useScroll';

import { DIRECTION } from '../constants';

export function useCancel({ thresholds }) {
  const { direction, position } = useScroll();

  return useMemo(
    function factory() {
      return direction === DIRECTION.DOWN
        ? position < thresholds[0] - 1 || position >= thresholds[1] - 1
        : position < thresholds[0] || position >= thresholds[1];
    },
    [direction, thresholds],
  );
}
