export function getScrollBarWidth() {
  const inner = document.createElement('p');
  inner.style.width = '100%';
  inner.style.height = '200px';

  const outer = document.createElement('div');
  outer.style.position = 'absolute';
  outer.style.top = '0px';
  outer.style.left = '0px';
  outer.style.visibility = 'hidden';
  outer.style.width = '200px';
  outer.style.height = '150px';
  outer.style.overflow = 'hidden';
  outer.appendChild(inner);
  document.body.appendChild(outer);

  const fullWidth = inner.offsetWidth;
  outer.style.overflow = 'scroll';

  let contentWidth = inner.offsetWidth;

  if (fullWidth === contentWidth) {
    contentWidth = outer.clientWidth;
  }

  document.body.removeChild(outer);

  return (fullWidth - contentWidth);
}

export function getScrollTop() {
  return typeof document === 'undefined' ? 0 : document.scrollingElement.scrollTop;
}

export function getWindowHeight() {
  return typeof document === 'undefined' ? 0 : document.documentElement.offsetHeight;
}

export function getWindowWidth() {
  return typeof document === 'undefined' ? 0 : document.documentElement.offsetWidth;
}

export function scrollToPosition(position) {
  // Safari messes up without the .ceil
  document.scrollingElement.scrollTo({ top: Math.ceil(position * getWindowHeight()) });
}
