import { useCallback, useLayoutEffect, useState } from 'react';

const INITIAL_SIZE = { height: 0, width: 0 };

export function useSize({ boundings = 'offset', ref }) {
  const [size, setSize] = useState(INITIAL_SIZE);

  const onResize = useCallback(
    function callback() {
      if (!ref.current) return;

      const { [`${boundings}Height`]: height, [`${boundings}Width`]: width } = ref.current;

      if (height !== size.height || width !== size.width) {
        setSize({ height, width });
      }
    },
    [size.height, size.width]
  );

  useLayoutEffect(
    function effect() {
      if (!ref.current) {
        if (size.height || size.width) {
          setSize(INITIAL_SIZE);
        }

        return undefined;
      }

      const resizeObserver = new ResizeObserver(onResize);
      resizeObserver.observe(ref.current);

      return function cleanUp() {
        resizeObserver.disconnect();
      };
    },
    [ref.current, onResize]
  );

  return size;
}
