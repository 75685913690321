import { useEffect, useState } from 'react';

export function useMaximum({ enabled, value }) {
  const [maximum, setMaximum] = useState(-1);

  useEffect(
    function effect() {
      if (enabled && value > maximum) {
        setMaximum(value);
      }
    },
    [value],
  );

  return maximum;
}
