import { useEffect, useRef } from 'react';

export function useMount(initialState = false) {
  const mounted = useRef(initialState);

  useEffect(
    function effect() {
      mounted.current = true;

      return function cleanUp() {
        mounted.current = false;
      };
    },
    []
  );

  return mounted;
}
