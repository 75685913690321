import React, { createContext, useEffect, useRef, useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { getScrollBarWidth, getWindowHeight, getWindowWidth } from '../utils/window';
import { useMount } from '../hooks';

export const WIDTH = {
  true: 1440,
  false: 390,
};

export const HEIGHT = {
  true: 758,
  false: 680,
};

const initialValue = {
  scale: 1,
  width: 0,
  height: 0,
  scaledBy: 'width',
  left: 0,
  top: 0,
};

export const BoundsContext = createContext(initialValue);
BoundsContext.displayName = 'BoundsContext';

export default function ContextWrapper({ children, location, path }) {
  const mounted = useMount();
  const frame = useRef();
  const scrollbarWidth = useRef(0);
  const breakpoints = useBreakpoint();
  const [container, setContainer] = useState(initialValue);
  const [windowBounds, setWindowBounds] = useState({ width: 0, height: 0 });

  useEffect(
    function effect() {
      scrollbarWidth.current = getScrollBarWidth();

      function updateBounds() {
        if (!mounted.current) return;

        setWindowBounds({ width: getWindowWidth(), height: getWindowHeight() });
      }

      function onResize() {
        if (frame.current) {
          cancelAnimationFrame(frame.current);
        }

        frame.current = requestAnimationFrame(updateBounds);
      }

      onResize();
      window.addEventListener('resize', onResize);

      return function cleanup() {
        if (frame.current) {
          cancelAnimationFrame(frame.current);
        }

        window.removeEventListener('resize', onResize);
      };
    },
    [],
  );

  useEffect(
    function effect() {
      if (!location.hash) {
        document.scrollingElement.scrollTo({ top: 0 });
      }
    },
    [path],
  );

  useEffect(
    function effect() {
      const shift = scrollbarWidth.current;
      const newContainer = {
        width: WIDTH[breakpoints.laptop],
        height: HEIGHT[breakpoints.laptop],
      };
      const windowAspect = (windowBounds.width - shift) / windowBounds.height;
      const containerAspect = newContainer.width / newContainer.height;

      if (windowAspect < containerAspect) {
        newContainer.scale = (windowBounds.width - shift) / newContainer.width;
        newContainer.scaledBy = 'width';
        newContainer.fullWidth = newContainer.width;
        newContainer.fullHeight = windowBounds.height / newContainer.scale;
        newContainer.top = (newContainer.fullHeight - newContainer.height) / 2;
        newContainer.left = 0;
      } else {
        newContainer.scale = windowBounds.height / newContainer.height;
        newContainer.scaledBy = 'height';
        newContainer.fullWidth = (windowBounds.width - shift) / newContainer.scale;
        newContainer.fullHeight = newContainer.height;
        newContainer.top = 0;
        newContainer.left = (newContainer.fullWidth - newContainer.width) / 2;
      }

      setContainer(newContainer);
    },
    [windowBounds],
  );

  return (
    <BoundsContext.Provider value={container}>
      {children}
    </BoundsContext.Provider>
  );
}
